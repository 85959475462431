import { Outlet } from '@remix-run/react';

import { ConfirmCancelModalRoot } from '../components/ConfirmCancelModalContext';
import { ProvidersList } from '../components/ProvidersList';
import { RoutedBlockProviders } from '../components/RoutedBlock';

function BlockHome() {
  const parents = [<RoutedBlockProviders />];

  return (
    <ProvidersList providers={parents}>
      <Outlet />
      <ConfirmCancelModalRoot />
    </ProvidersList>
  );
}

export const Component = BlockHome;
